import React from "react"
import { navigate } from "gatsby-link"

const InviteRedirect = ({ pageResources, location }) => {
  if (!pageResources) {
    return null
  }

  React.useEffect(() => {
    const path = pageResources.page.path
    const ref = location.pathname.replace(path, "")

    navigate(`/register/?ref=${ref}`)
  }, [])

  return null
}

export default InviteRedirect
